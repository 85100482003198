"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.debounce = void 0;
function debounce(func, waitMilliseconds) {
    if (waitMilliseconds === void 0) { waitMilliseconds = 500; }
    var timeoutId;
    // tslint:disable-next-line: only-arrow-functions
    return function (t) {
        var args = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            args[_i - 1] = arguments[_i];
        }
        var context = t;
        var doLater = function () {
            timeoutId = undefined;
            func.apply(context, args);
        };
        if (timeoutId !== undefined) {
            clearTimeout(timeoutId);
        }
        timeoutId = window.setTimeout(doLater, waitMilliseconds);
    };
}
exports.debounce = debounce;
